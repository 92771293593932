import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { Col, Row, Container } from "react-bootstrap";
import EventCard from "../components/EventCard";
import EventCardPast from "../components/EvemtCardPast";

const ContactPage = () => {
	const tl = useRef();
	const {
		wpEventsPage: { eventPageFields, seoFields },
		allWpEvent = { nodes: [] },
		site,
	} = useStaticQuery(graphql`
		query {
			allWpEvent {
				nodes {
					title
					slug
					pastEventFields {
						isThisAPastEvent
					}
					eventsField {
						cardDescription
						gallery {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						heroSection {
							eventDate
							time
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
			wpEventsPage {
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				eventPageFields {
					heading
					heading2
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const { heading, heading2 } = eventPageFields;
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Events",
				item: {
					url: `${siteUrl}/events`,
					id: `${siteUrl}/events}`,
				},
			},
		],
	};

	const nonPastEvents = allWpEvent.nodes.filter(
		(event) => !event.pastEventFields.isThisAPastEvent
	);

	const pastEvents = allWpEvent.nodes.filter(
		(event) => event.pastEventFields.isThisAPastEvent
	);

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/events`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.localFile?.publicURL}`,
							width: `${seoFields?.image?.localFile?.childImageSharp?.original?.width}`,
							height: `${seoFields?.image?.localFile?.childImageSharp?.original?.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col className="text-center">
									<h1 className=" text-center mb-5 display-5">{heading}</h1>
								</Col>
							</Row>
							<Row className="gx-4 j-100 gy-5">
								{nonPastEvents.map((event, i) => (
									<EventCard key={i} event={event} />
								))}
							</Row>
						</Container>
					</section>
					{pastEvents.length > 0 && (
						<section className="py-5 pb-lg-7">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className=" text-center mb-5 display-5">{heading2}</h2>
									</Col>
								</Row>
								<Row className="gx-4 j-100 gy-5">
									{pastEvents.map((event, i) => (
										<EventCardPast key={i} event={event} />
									))}
								</Row>
							</Container>
						</section>
					)}
				</Layout>
			</div>
		</>
	);
};

export default ContactPage;
