// src/components/EventCard.js
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Button, Carousel } from "react-bootstrap";

const EventCardPast = ({ event }) => {
	const {
		heroSection = {},
		gallery = [],
		cardDescription = "",
	} = event.eventsField || {};
	const mainImage = heroSection.image || {};
	var images = [];

	if (gallery !== null && gallery.length > 0) {
		images = [mainImage, ...gallery].filter((image) => image.localFile);
	}

	return (
		<Col style={{ minHeight: "100%" }} md={6} lg={4}>
			<div className="position-relative h-100">
				{gallery?.length > 0 ? (
					<Carousel controls={false}>
						{images.map((image, index) => (
							<Carousel.Item key={index}>
								<GatsbyImage
									className="w-100 image-height-new-events"
									style={{ height: "250px" }}
									image={image.localFile.childImageSharp.gatsbyImageData}
									alt={image.altText || ""}
								/>
							</Carousel.Item>
						))}
					</Carousel>
				) : (
					<GatsbyImage
						className="w-100 image-height-new-events"
						style={{ height: "250px" }}
						image={mainImage.localFile?.childImageSharp?.gatsbyImageData}
						alt={mainImage.altText || ""}
					/>
				)}
				<h3 className="fs-4 my-3">{event.title}</h3>
				<p style={{ fontSize: "90%" }}>{cardDescription}</p>
				<div style={{ height: "70px" }}></div>
				<div className="position-absolute start-0 bottom-0 w-100">
					<div className="d-flex align-items-center">
						<div className="d-block">
							<p className="pb-0 mb-0">Event date</p>
							<p className="Garamond-Bold fs-6">{heroSection.eventDate}</p>
						</div>
						<div className="px-4 pb-3">|</div>
						<div className="d-block">
							<p className="pb-0 mb-0">Time</p>
							<p className="Garamond-Bold fs-6">{heroSection.time}</p>
						</div>
					</div>
				</div>
			</div>
		</Col>
	);
};

export default EventCardPast;
